var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container add-course-container" },
    [
      _c("el-row", { staticClass: "mb40" }, [
        _c(
          "div",
          {
            staticStyle: {
              display: "flex",
              "align-items": "center",
              "justify-content": "space-between",
            },
          },
          [
            _c(
              "div",
              [
                _vm._v(" 添加课程 "),
                _c(
                  "el-button",
                  {
                    staticClass: "ml10",
                    attrs: { type: "primary", size: "mini", plain: "" },
                    on: { click: _vm.checkCourseVisibleFn },
                  },
                  [_vm._v("去添加")]
                ),
              ],
              1
            ),
            _c("i", {
              staticClass: "el-icon-close",
              on: { click: _vm.handleCancel },
            }),
          ]
        ),
      ]),
      _vm.checkCourseList.length > 0
        ? _c(
            "el-row",
            [
              _c(
                "el-form",
                {
                  ref: "courseForm",
                  attrs: {
                    model: { checkCourseList: _vm.checkCourseList },
                    "inline-message": "",
                    "label-width": "0",
                  },
                },
                [
                  _c(
                    "el-table",
                    { attrs: { data: _vm.checkCourseList, border: "" } },
                    [
                      _c("el-table-column", {
                        attrs: {
                          label: "课程编号",
                          align: "center",
                          prop: "id",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: { label: "课程名称", align: "name" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        prop:
                                          "checkCourseList." +
                                          scope.$index +
                                          ".name",
                                        rules: {
                                          required: true,
                                          message: "请输入课程名称",
                                          trigger: "blur",
                                        },
                                      },
                                    },
                                    [
                                      _c("el-input", {
                                        model: {
                                          value: scope.row.name,
                                          callback: function ($$v) {
                                            _vm.$set(scope.row, "name", $$v)
                                          },
                                          expression: "scope.row.name",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          1956585706
                        ),
                      }),
                      _c("el-table-column", {
                        attrs: { label: "单价", align: "center" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        prop:
                                          "checkCourseList." +
                                          scope.$index +
                                          ".price",
                                        rules: {
                                          required: true,
                                          validator: _vm.checkPrice,
                                          trigger: "blur",
                                        },
                                      },
                                    },
                                    [
                                      _c("el-input", {
                                        on: {
                                          input: function ($event) {
                                            return _vm.priceInput(
                                              $event,
                                              scope.$index
                                            )
                                          },
                                        },
                                        model: {
                                          value: scope.row.price,
                                          callback: function ($$v) {
                                            _vm.$set(scope.row, "price", $$v)
                                          },
                                          expression: "scope.row.price",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          1829732615
                        ),
                      }),
                      _c("el-table-column", {
                        attrs: { label: "封面图", align: "center" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        prop:
                                          "checkCourseList." +
                                          scope.$index +
                                          ".avatar",
                                        rules: {
                                          required: true,
                                          message: "请上传封面图",
                                          trigger: "blur",
                                        },
                                      },
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "flex flex-wrap align-center justify-center",
                                        },
                                        [
                                          _c("ImageUpload", {
                                            attrs: {
                                              limit: 1,
                                              isShowTip: false,
                                              value: scope.row.avatar,
                                            },
                                            on: {
                                              input: function ($event) {
                                                return _vm.handleUpload(
                                                  $event,
                                                  "avatar",
                                                  scope.$index
                                                )
                                              },
                                            },
                                          }),
                                          _c("div", { staticClass: "tip" }, [
                                            _vm._v("尺寸：180*180"),
                                          ]),
                                        ],
                                        1
                                      ),
                                    ]
                                  ),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          1690123029
                        ),
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: "轮播图",
                          align: "center",
                          width: "300",
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        prop:
                                          "checkCourseList." +
                                          scope.$index +
                                          ".bannerImages",
                                        rules: {
                                          required: true,
                                          message: "请上传轮播图",
                                          trigger: "blur",
                                        },
                                      },
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "flex flex-wrap align-center justify-center",
                                        },
                                        [
                                          _c("ImageUpload", {
                                            attrs: {
                                              limit: 5,
                                              isShowTip: false,
                                              value: scope.row.bannerImages,
                                            },
                                            on: {
                                              input: function ($event) {
                                                return _vm.handleUpload(
                                                  $event,
                                                  "bannerImages",
                                                  scope.$index
                                                )
                                              },
                                            },
                                          }),
                                          _c("div", { staticClass: "tip" }, [
                                            _c("p", [_vm._v("尺寸：180*180")]),
                                            _c("p", [
                                              _vm._v("最多可上传"),
                                              _c("strong", [_vm._v("5")]),
                                              _vm._v("张"),
                                            ]),
                                          ]),
                                        ],
                                        1
                                      ),
                                    ]
                                  ),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          4018456362
                        ),
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: "详情介绍",
                          align: "center",
                          prop: "price",
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _c(
                                    "el-button",
                                    {
                                      attrs: { type: "text" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.editContentDetail(
                                            scope.row,
                                            scope.$index
                                          )
                                        },
                                      },
                                    },
                                    [_vm._v("编辑")]
                                  ),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          874288736
                        ),
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: "视频",
                          align: "center",
                          prop: "vid_count",
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _vm._v(
                                    " " + _vm._s(scope.row.vid_count) + " "
                                  ),
                                  _c(
                                    "el-button",
                                    {
                                      staticClass: "ml10",
                                      attrs: { type: "text" },
                                      on: {
                                        click: function ($event) {
                                          _vm.checkCourseIndex = scope.$index
                                          _vm.openVideoMore = true
                                        },
                                      },
                                    },
                                    [
                                      _vm._v("查看编辑"),
                                      _c("i", {
                                        staticClass: "el-icon-arrow-right",
                                      }),
                                    ]
                                  ),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          3221523180
                        ),
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: "是否上架",
                          align: "center",
                          width: "200",
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _c("el-switch", {
                                    attrs: {
                                      "inline-prompt": "",
                                      "active-value": "1",
                                      "inactive-value": "0",
                                      "inactive-text": "下架",
                                      "active-text": "上架",
                                    },
                                    model: {
                                      value: scope.row.release,
                                      callback: function ($$v) {
                                        _vm.$set(scope.row, "release", $$v)
                                      },
                                      expression: "scope.row.release",
                                    },
                                  }),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          2832303854
                        ),
                      }),
                      _c("el-table-column", {
                        attrs: { fixed: "right", label: "操作", width: "100" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _c(
                                    "el-button",
                                    {
                                      attrs: { type: "text", size: "small" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.handleDeleteClick(
                                            scope.row
                                          )
                                        },
                                      },
                                    },
                                    [_vm._v("删除")]
                                  ),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          2543554020
                        ),
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm.checkCourseList.length > 0
        ? _c("el-row", [
            _c(
              "div",
              { staticClass: "handle-btn" },
              [
                _c(
                  "el-button",
                  { staticClass: "btn mr20", on: { click: _vm.handleCancel } },
                  [_vm._v("取消")]
                ),
                _c(
                  "el-button",
                  {
                    staticClass: "btn",
                    attrs: { type: "primary" },
                    on: { click: _vm.handleSubmit },
                  },
                  [_vm._v("确定")]
                ),
              ],
              1
            ),
          ])
        : _vm._e(),
      _c("checkCourse", {
        ref: "checkCourse",
        attrs: {
          checkCourseVisible: _vm.checkCourseVisible,
          checkCourseIds: _vm.checkCourseList,
        },
        on: {
          previewDetail: _vm.previewDetail,
          "update:checkCourseVisible": function ($event) {
            _vm.checkCourseVisible = $event
          },
          "update:check-course-visible": function ($event) {
            _vm.checkCourseVisible = $event
          },
          _enter: _vm.handleEnterCourse,
        },
      }),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "视频预览",
            visible: _vm.openVideoPlay,
            width: "800px",
            "append-to-body": "",
          },
          on: {
            close: function () {
              _vm.openVideoPlay = false
              _vm.currentVideoUrl = ""
            },
            "update:visible": function ($event) {
              _vm.openVideoPlay = $event
            },
          },
        },
        [
          _c("video", {
            staticStyle: { width: "720px" },
            attrs: { src: _vm.currentVideoUrl, autoplay: "", controls: "" },
          }),
        ]
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "视频数量",
            visible: _vm.openVideoMore,
            width: "800px",
            "append-to-body": "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.openVideoMore = $event
            },
          },
        },
        [
          _c(
            "el-table",
            {
              staticStyle: { width: "100%" },
              attrs: {
                data:
                  _vm.checkCourseList[_vm.checkCourseIndex] &&
                  _vm.checkCourseList[_vm.checkCourseIndex].videos,
                height: "80vh",
              },
            },
            [
              _c("el-table-column", {
                attrs: { type: "index", label: "序号", width: "80" },
              }),
              _c("el-table-column", {
                attrs: { prop: "title", label: "视频名称", width: "180" },
              }),
              _c("el-table-column", {
                attrs: { prop: "coverurl", label: "视频封面" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("el-image", {
                          staticStyle: { width: "160px", height: "140px" },
                          attrs: {
                            src: scope.row.avatar || scope.row.coverurl,
                          },
                        }),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { fixed: "right", label: "操作", width: "100" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "el-button",
                          {
                            attrs: { type: "text", size: "small" },
                            on: {
                              click: function ($event) {
                                return _vm.handlePreview(scope.row)
                              },
                            },
                          },
                          [_vm._v("预览")]
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "编辑详情介绍",
            visible: _vm.previewEditContent,
            width: "900px",
            "before-close": function () {
              _vm.previewEditContent = false
            },
            center: "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.previewEditContent = $event
            },
          },
        },
        [
          _c("editor", {
            model: {
              value: _vm.editorText,
              callback: function ($$v) {
                _vm.editorText = $$v
              },
              expression: "editorText",
            },
          }),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function () {
                      _vm.previewEditContent = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.editContentSure },
                },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "预览",
            visible: _vm.previewContent,
            width: "900px",
            "before-close": _vm.handlePreviewContentClose,
            center: "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.previewContent = $event
            },
          },
        },
        [_c("div", { domProps: { innerHTML: _vm._s(_vm.previewContentHtml) } })]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }