<template>
  <div class="app-container add-course-container">
    <el-row class="mb40">
      <div
        style="
          display: flex;
          align-items: center;
          justify-content: space-between;
        "
      >
        <div>
          添加课程
          <el-button
            class="ml10"
            type="primary"
            size="mini"
            plain
            @click="checkCourseVisibleFn"
            >去添加</el-button
          >
        </div>
        <i class="el-icon-close" @click="handleCancel"></i>
      </div>
    </el-row>

    <el-row v-if="checkCourseList.length > 0">
      <el-form
        :model="{ checkCourseList }"
        inline-message
        ref="courseForm"
        label-width="0"
      >
        <el-table :data="checkCourseList" border>
          <el-table-column label="课程编号" align="center" prop="id">
          </el-table-column>
          <el-table-column label="课程名称" align="name">
            <template slot-scope="scope">
              <el-form-item
                :prop="`checkCourseList.${scope.$index}.name`"
                :rules="{
                  required: true,
                  message: '请输入课程名称',
                  trigger: 'blur',
                }"
              >
                <el-input v-model="scope.row.name"></el-input>
              </el-form-item>
            </template>
          </el-table-column>
          <el-table-column label="单价" align="center">
            <template slot-scope="scope">
              <el-form-item
                :prop="`checkCourseList.${scope.$index}.price`"
                :rules="{
                  required: true,
                  validator: checkPrice,
                  trigger: 'blur',
                }"
              >
                <el-input
                  v-model="scope.row.price"
                  @input="priceInput($event, scope.$index)"
                ></el-input>
              </el-form-item>
            </template>
          </el-table-column>
          <el-table-column label="封面图" align="center">
            <template slot-scope="scope">
              <el-form-item
                :prop="`checkCourseList.${scope.$index}.avatar`"
                :rules="{
                  required: true,
                  message: '请上传封面图',
                  trigger: 'blur',
                }"
              >
                <div class="flex flex-wrap align-center justify-center">
                  <ImageUpload
                    :limit="1"
                    :isShowTip="false"
                    @input="handleUpload($event, 'avatar', scope.$index)"
                    :value="scope.row.avatar"
                  />
                  <div class="tip">尺寸：180*180</div>
                </div>
              </el-form-item>
            </template>
          </el-table-column>
          <el-table-column label="轮播图" align="center" width="300">
            <template slot-scope="scope">
              <el-form-item
                :prop="`checkCourseList.${scope.$index}.bannerImages`"
                :rules="{
                  required: true,
                  message: '请上传轮播图',
                  trigger: 'blur',
                }"
              >
                <div class="flex flex-wrap align-center justify-center">
                  <ImageUpload
                    :limit="5"
                    :isShowTip="false"
                    @input="handleUpload($event, 'bannerImages', scope.$index)"
                    :value="scope.row.bannerImages"
                  />
                  <div class="tip">
                    <p>尺寸：180*180</p>
                    <p>最多可上传<strong>5</strong>张</p>
                  </div>
                </div>
              </el-form-item>
            </template>
          </el-table-column>
          <!-- <el-table-column label="详情图" align="center" width="300">
            <template slot-scope="scope">
              <el-form-item
                :prop="`checkCourseList.${scope.$index}.avatar`"
                :rules="{
                  required: true,
                  message: '请上传详情图',
                }"
              >
                <div class="flex flex-wrap align-center justify-center">
                  <ImageUpload
                    :limit="5"
                    :isShowTip="false"
                    @input="handleUpload($event, 'avatar', scope.$index)"
                    :value="scope.row.avatar"
                  />
                  <div class="tip">
                    <p>尺寸：180*180</p>
                    <p>最多可上传<strong>5</strong>张</p>
                  </div>
                </div>
              </el-form-item>
            </template>
          </el-table-column> -->
          <el-table-column label="详情介绍" align="center" prop="price">
            <template slot-scope="scope">
              <el-button
                type="text"
                @click="editContentDetail(scope.row, scope.$index)"
                >编辑</el-button
              >
            </template>
          </el-table-column>
          <el-table-column label="视频" align="center" prop="vid_count">
            <template slot-scope="scope">
              {{ scope.row.vid_count }}
              <el-button
                type="text"
                class="ml10"
                @click="
                  checkCourseIndex = scope.$index;
                  openVideoMore = true;
                "
                >查看编辑<i class="el-icon-arrow-right"></i
              ></el-button>
            </template>
          </el-table-column>
          <el-table-column label="是否上架" align="center" width="200">
            <template slot-scope="scope">
              <el-switch
                inline-prompt
                v-model="scope.row.release"
                active-value="1"
                inactive-value="0"
                inactive-text="下架"
                active-text="上架"
              >
              </el-switch>
            </template>
          </el-table-column>

          <el-table-column fixed="right" label="操作" width="100">
            <template slot-scope="scope">
              <el-button
                @click="handleDeleteClick(scope.row)"
                type="text"
                size="small"
                >删除</el-button
              >
            </template>
          </el-table-column>
        </el-table>
      </el-form>
    </el-row>
    <el-row v-if="checkCourseList.length > 0">
      <div class="handle-btn">
        <el-button class="btn mr20" @click="handleCancel">取消</el-button>
        <el-button type="primary" class="btn" @click="handleSubmit"
          >确定</el-button
        >
      </div>
    </el-row>
    <checkCourse
      @previewDetail="previewDetail"
      :checkCourseVisible.sync="checkCourseVisible"
      :checkCourseIds="checkCourseList"
      @_enter="handleEnterCourse"
      ref="checkCourse"
    />
    <el-dialog
      @close="
        () => {
          openVideoPlay = false;
          currentVideoUrl = '';
        }
      "
      title="视频预览"
      :visible.sync="openVideoPlay"
      width="800px"
      append-to-body
    >
      <video
        style="width: 720px"
        :src="currentVideoUrl"
        autoplay
        controls
      ></video>
    </el-dialog>
    <el-dialog
      title="视频数量"
      :visible.sync="openVideoMore"
      width="800px"
      append-to-body
    >
      <el-table
        :data="
          checkCourseList[checkCourseIndex] &&
          checkCourseList[checkCourseIndex].videos
        "
        style="width: 100%"
        height="80vh"
      >
        <el-table-column type="index" label="序号" width="80">
        </el-table-column>
        <el-table-column prop="title" label="视频名称" width="180">
        </el-table-column>
        <el-table-column prop="coverurl" label="视频封面">
          <template slot-scope="scope">
            <el-image
              :src="scope.row.avatar || scope.row.coverurl"
              style="width: 160px; height: 140px"
            />
          </template>
        </el-table-column>
        <el-table-column fixed="right" label="操作" width="100">
          <template slot-scope="scope">
            <el-button
              @click="handlePreview(scope.row)"
              type="text"
              size="small"
              >预览</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </el-dialog>

    <!-- <courseVideoList
      :courseVideoVisible.sync="courseVideoVisible"
      :videoList="
        checkCourseList[checkCourseIndex] &&
        checkCourseList[checkCourseIndex].videos
      "
      :dialogTitle="
        checkCourseList[checkCourseIndex] &&
        checkCourseList[checkCourseIndex].name
      "
      @_saveVideo="handleSaveVideo"
    /> -->
    <el-dialog
      title="编辑详情介绍"
      :visible.sync="previewEditContent"
      width="900px"
      :before-close="
        () => {
          previewEditContent = false;
        }
      "
      center
    >
      <editor v-model="editorText" />
      <span slot="footer" class="dialog-footer">
        <el-button
          @click="
            () => {
              previewEditContent = false;
            }
          "
          >取 消</el-button
        >
        <el-button type="primary" @click="editContentSure">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog
      title="预览"
      :visible.sync="previewContent"
      width="900px"
      :before-close="handlePreviewContentClose"
      center
    >
      <div v-html="previewContentHtml"></div>
    </el-dialog>
  </div>
</template>

<script>
import checkCourse from "./components/checkCourse.vue";
import courseVideoList from "./components/courseVideoList";
import ImageUpload from "@/components/ImageUpload/newUpload";
import {
  addOnlineList,
  getOnlineDetail,
} from "@/api/virtualshoppingmall/onlineclass";

export default {
  components: { checkCourse, courseVideoList, ImageUpload },
  data() {
    return {
      previewEditContent: false,
      previewContent: false,
      previewContentHtml: "",
      editorText: "",

      currentVideoUrl: "",
      openVideoPlay: false,
      openVideoMore: false,
      checkCourseVisible: false, //选择课程弹窗
      courseVideoVisible: false, //课程视频弹窗
      checkCourseIds: [], //选择的课程id集合
      checkCourseIndex: null, //选择视频时的课程索引
      checkCourseList: [
        // {
        //   lessonNo: "202405210001",
        //   lessonName: "校区招生7大秘诀",
        //   price: 3000,
        //   release: "1",
        //   coverImage: [],
        //   bannerImages: [],
        //   detailImages: [],
        //   videoList: [
        //     {
        //       name: "校区招生7大秘诀第1节",
        //       coverImage: "",
        //       videoSrc: "",
        //     },
        //   ],
        // },
      ],
      contentCurrentIndex: null,
    };
  },
  methods: {
    editContentSure() {
      console.log("this.editorText", this.editorText);
      this.checkCourseList[this.contentCurrentIndex].content = this.editorText;
      this.previewEditContent = false;
    },
    // 编辑详情介绍
    editContentDetail(row, index) {
      console.log("row", row);
      this.contentCurrentIndex = index;
      this.previewEditContent = true;

      this.editorText = row.content;
    },
    previewDetail(e) {
      this.previewContent = true;
      this.previewContentHtml = e.content;
    },
    handlePreviewContentClose() {
      this.previewContent = false;
    },
    handlePreview(row) {
      console.log("row", row);
      const loading = this.$loading({
        lock: true,
        text: "加载中...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      getOnlineDetail({
        id: this.checkCourseList[this.checkCourseIndex].id,
      }).then((res) => {
        console.log(res);
        let videoList = [];
        res.data.child_list.forEach((item) => {
          item.record.forEach((v) => {
            videoList.push(v);
          });
        });
        console.log("videoList", videoList);

        videoList.forEach((v) => {
          if (row.id == v.id) {
            this.currentVideoUrl = v.url;
            console.log(this.currentVideoUrl);
          }
        });
        loading.close();
        this.openVideoPlay = true;
      });
    },
    getOnlineDetailFn() {
      getOnlineDetail({ id: this.lessonId }).then((res) => {
        console.log(res);
        let videoList = [];
        res.data.child_list.forEach((item) => {
          item.record.forEach((v) => {
            videoList.push(v);
          });
        });
        console.log("videoList", videoList);

        this.videoList = videoList;
      });
    },
    checkCourseVisibleFn() {
      this.checkCourseVisible = true;
      // this.$nextTick(() => {
      //   this.$refs.checkCourse.$refs.multipleTable.clearSelection();
      //   this.checkCourseList.forEach((element) => {
      //     console.log(element);
      //     this.$refs.checkCourse.$refs.multipleTable.toggleRowSelection(
      //       element,
      //       true
      //     );
      //   });
      // });
    },
    handleDeleteClick(e) {
      console.log("e", e);
      this.$confirm("确定删除该课程吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.checkCourseList = this.checkCourseList.filter((item) => {
            return item.id !== e.id;
          });
        })
        .catch(() => {
          console.log("取消");
        });
    },
    // 保存 新增/编辑操作
    handleSubmit() {
      console.log("this.checkCourseList", this.checkCourseList);

      this.$refs["courseForm"].validate((valid, obj) => {
        if (valid) {
          // this.$message.success("保存成功");
          // this.$router.go(-1);
          console.log(this.checkCourseList);

          this.checkCourseList.forEach((v1) => {
            v1.videos = [];
            v1.sub_list &&
              v1.sub_list.forEach((v2) => {
                v2.record &&
                  v2.record.forEach((v3) => {
                    v1.videos.push({
                      ...v3,
                      pid: v2.id,
                      coverurl: v2.avatar || v3.coverurl,
                    });
                  });
              });
          });
          console.log("videos", this.checkCourseList);

          if (this.checkCourseList.length === 1) {
            if (this.checkCourseList[0].videos.length === 0) {
              this.$confirm("该课程视频数量为0，确定添加？", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
              })
                .then((res) => {
                  let data = this.checkCourseList.map((item) => {
                    return {
                      sourceId: item.id,
                      lessonName: item.name,
                      unitPrice: item.price,
                      release: item.release,
                      coverImage: item.avatar,
                      bannerImages: item.bannerImages.split(",").map((v) => {
                        return {
                          path: v,
                        };
                      }),
                      detailImages: [],
                      homeShow: 1,
                      content: item.content,
                      subCount: item.vid_count,
                      videos: item.videos,
                    };
                  });

                  addOnlineList(data).then((res) => {
                    this.$message.success("保存成功");
                    localStorage.setItem("operateType", "add");
                    this.$router.go(-1);
                  });
                })
                .catch((err) => {
                  console.log("err", err);
                });
            } else {
              let data = this.checkCourseList.map((item) => {
                return {
                  sourceId: item.id,
                  lessonName: item.name,
                  unitPrice: item.price,
                  release: item.release,
                  coverImage: item.avatar,
                  bannerImages: item.bannerImages.split(",").map((v) => {
                    return {
                      path: v,
                    };
                  }),
                  detailImages: [],
                  homeShow: 1,
                  content: item.content,
                  subCount: item.vid_count,
                  videos: item.videos,
                };
              });

              addOnlineList(data).then((res) => {
                this.$message.success("保存成功");
                localStorage.setItem("operateType", "add");
                this.$router.go(-1);
              });
            }
          }

          if (this.checkCourseList.length > 1) {
            // 使用 filter 方法筛选出 video 长度为 0 的对象
            const emptyVideoItems = this.checkCourseList.filter(
              (item) => item.videos.length === 0
            );

            // 使用 map 方法提取这些对象的 id
            const emptyVideoIds = emptyVideoItems.map((item) => item.id);
            console.log("emptyVideoIds", emptyVideoIds);

            if (emptyVideoIds.length > 0) {
              this.$confirm(
                `课程${emptyVideoIds.join("、")}视频数量为0，确定添加？`,
                "提示",
                {
                  confirmButtonText: "确定",
                  cancelButtonText: "取消",
                  type: "warning",
                }
              )
                .then((res) => {
                  let data = this.checkCourseList.map((item) => {
                    return {
                      sourceId: item.id,
                      lessonName: item.name,
                      unitPrice: item.price,
                      release: item.release,
                      coverImage: item.avatar,
                      bannerImages: item.bannerImages.split(",").map((v) => {
                        return {
                          path: v,
                        };
                      }),
                      detailImages: [],
                      homeShow: 1,
                      content: item.content,
                      subCount: item.vid_count,
                      videos: item.videos,
                    };
                  });

                  addOnlineList(data).then((res) => {
                    this.$message.success("保存成功");
                    localStorage.setItem("operateType", "add");
                    this.$router.go(-1);
                  });
                })
                .catch((err) => {
                  console.log("err", err);
                });
            } else {
              let data = this.checkCourseList.map((item) => {
                return {
                  sourceId: item.id,
                  lessonName: item.name,
                  unitPrice: item.price,
                  release: item.release,
                  coverImage: item.avatar,
                  bannerImages: item.bannerImages.split(",").map((v) => {
                    return {
                      path: v,
                    };
                  }),
                  detailImages: [],
                  homeShow: 1,
                  content: item.content,
                  subCount: item.vid_count,
                  videos: item.videos,
                };
              });

              addOnlineList(data).then((res) => {
                this.$message.success("保存成功");
                localStorage.setItem("operateType", "add");
                this.$router.go(-1);
              });
            }
          }
        } else {
          console.log("error submit!!", obj);
          return false;
        }
      });
    },
    // 取消 编辑/新增
    handleCancel() {
      // 调用全局挂载的方法，关闭当前页
      this.$store.dispatch("tagsView/delView", this.$route);
      // 返回上一步路由
      this.$router.go(-1);
    },
    // 保存视频编辑操作
    handleSaveVideo(data) {
      this.$set(this.checkCourseList[this.checkCourseIndex], "videoList", data);
    },
    // 上传成功回调
    handleUpload(data, str, index) {
      console.log("data", data);
      let imgs = data
        .map((v) => {
          return v.url;
        })
        .join();

      // this.$set(this.checkCourseList[index], `${str}`, imgs);
      // console.log("77788", this.checkCourseList);

      this.checkCourseList[index][str] = imgs;
      this.$nextTick(() => {
        this.$refs.courseForm.validateField(`checkCourseList.${index}.${str}`);
      });
      this.$forceUpdate();
    },
    // 选中课程
    handleEnterCourse({ courseIds, courseList }) {
      console.log(111111, courseIds, courseList);
      this.checkCourseVisible = false;
      this.checkCourseList = [];
      this.checkCourseList = [...this.checkCourseList, ...courseIds];

      this.checkCourseList.forEach((v1) => {
        v1.videos = [];
        v1.bannerImages = v1.slide.join();
        v1.sub_list &&
          v1.sub_list.forEach((v2) => {
            v2.record &&
              v2.record.forEach((v3) => {
                v1.videos.push({
                  ...v3,
                  pid: v2.id,
                });
              });
          });
      });
      console.log(6666, this.checkCourseList);

      this.checkCourseIds = courseIds;
    },
    // 校验单价
    checkPrice(rule, value, callback) {
      if (value === "") {
        callback(new Error("请输入单价"));
      } else {
        callback();
      }
    },
    // 输入金额正则
    priceInput(value, index) {
      let val = value
        .replace(/[^\d.]/g, "")
        .replace(/\.{2,}/g, ".")
        .replace(".", "$#$")
        .replace(/\./g, "")
        .replace("$#$", ".")
        .replace(/^(\-)*(\d+)\.(\d\d).*$/, "$1$2.$3")
        .replace(/^\./g, "")
        .replace(/^0\d/, "0");
      this.$set(this.checkCourseList[index], "price", val);
    },
  },
};
</script>
<style lang="scss">
.add-course-container {
  .tip {
    font-size: 12px;
    line-height: 1.5;
    color: #7f7f7f;
    p {
      margin: 0;
    }
  }
  .handle-btn {
    text-align: right;
    margin-top: 200px;
    .btn {
      width: 132px;
    }
  }
  .el-form-item {
    margin-bottom: 0;
  }
  .el-switch__label--left {
    position: relative;
    left: 70px;
    color: #fff;
    z-index: -1111;
    span {
      width: 40px;
    }
  }
  .el-switch__label--right {
    position: relative;
    right: 70px;
    color: #fff;
    z-index: -1111;
    span {
      display: inline-block;
      width: 40px;
    }
  }
  .el-switch__core {
    width: 65px !important;
  }
  .el-switch__label--right.is-active {
    z-index: 10;
    color: #fff !important;
  }
  .el-switch__label--left.is-active {
    z-index: 10;
    color: #9c9c9c !important;
  }
  .avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  .avatar-uploader .el-upload:hover {
    border-color: #409eff;
  }
  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 60px;
    height: 60px;
    line-height: 60px;
    text-align: center;
  }
  .el-upload--picture-card {
    width: 60px;
    height: 60px;
    line-height: 60px;
    display: inline-block;
    margin-right: 10px;
    .el-icon-plus {
      line-height: 60px;
    }
  }
  .hide .el-upload--picture-card {
    display: none;
  }
  .el-upload-list--picture-card .el-upload-list__item {
    width: 60px;
    height: 60px;
    margin-bottom: 0;
  }
}
</style>
