var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container edit-course-container" },
    [
      _c(
        "el-form",
        {
          ref: "form",
          attrs: { model: _vm.form, "label-width": "120px", rules: _vm.rules },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "课程名称", prop: "lessonName" } },
            [
              _c("el-input", {
                staticStyle: { width: "300px" },
                model: {
                  value: _vm.form.lessonName,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "lessonName", $$v)
                  },
                  expression: "form.lessonName",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "单价", prop: "unitPrice" } },
            [
              _c(
                "el-input",
                {
                  staticStyle: { width: "300px" },
                  attrs: { placeholder: "请输入内容", type: "number" },
                  model: {
                    value: _vm.form.unitPrice,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "unitPrice", $$v)
                    },
                    expression: "form.unitPrice",
                  },
                },
                [_c("template", { slot: "append" }, [_vm._v("元")])],
                2
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "封面图", prop: "coverImage" } },
            [
              _c("ImageUpload", {
                attrs: {
                  limit: 1,
                  fileSize: 50,
                  fileWidthAndHeight: "720 * 520",
                  isShowTip: true,
                  value: _vm.form.coverImage,
                },
                on: { input: _vm.coverInput },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "轮播图", prop: "bannerImages" } },
            [
              _c("ImageUpload", {
                attrs: {
                  limit: 5,
                  fileSize: 50,
                  fileWidthAndHeight: "720 * 520",
                  isShowTip: true,
                  value: _vm.form.bannerImages,
                },
                on: { input: _vm.bannerInput },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "详情页", prop: "content" } },
            [
              _c("editor", {
                attrs: { "min-height": 180 },
                model: {
                  value: _vm.form.content,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "content", $$v)
                  },
                  expression: "form.content",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "是否上架", prop: "release" } },
            [
              _c("el-switch", {
                staticClass: "main-switch",
                attrs: {
                  width: 60,
                  "active-value": "1",
                  "inactive-value": "0",
                  "inactive-text": "下架",
                  "active-text": "上架",
                  readonly: "",
                },
                model: {
                  value: _vm.form.release,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "release", $$v)
                  },
                  expression: "form.release",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "视频数量" } },
            [
              _c("span", [
                _vm._v(
                  _vm._s(_vm.form.videos && _vm.form.videos.length) + "个"
                ),
              ]),
              _c(
                "el-form",
                {
                  ref: "videosForm",
                  attrs: {
                    model: { videos: _vm.videos },
                    "inline-message": "",
                  },
                },
                [
                  _c(
                    "el-table",
                    { attrs: { data: _vm.videos } },
                    [
                      _c("el-table-column", {
                        attrs: { type: "index", label: "排序", width: "50" },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "title",
                          label: "视频名称",
                          width: "320",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      prop: "videos." + scope.$index + ".title",
                                      rules: {
                                        required: true,
                                        message: "请输入课视频名称",
                                        trigger: "blur",
                                      },
                                    },
                                  },
                                  [
                                    _c("el-input", {
                                      staticStyle: { width: "280px" },
                                      attrs: {
                                        placeholder: "请输入视频名称",
                                        type: "text",
                                      },
                                      model: {
                                        value: scope.row.title,
                                        callback: function ($$v) {
                                          _vm.$set(scope.row, "title", $$v)
                                        },
                                        expression: "scope.row.title",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ]
                            },
                          },
                        ]),
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "coverurl",
                          label: "视频封面",
                          width: "300",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      prop:
                                        "videos." + scope.$index + ".coverurl",
                                      rules: {
                                        required: true,
                                        message: "请上传视频封面图",
                                        trigger: "change",
                                      },
                                    },
                                  },
                                  [
                                    _c("ImageUpload", {
                                      attrs: {
                                        limit: 1,
                                        isShowTip: false,
                                        value: scope.row.coverurl,
                                      },
                                      on: {
                                        input: function ($event) {
                                          return _vm.videoCoverInput(
                                            $event,
                                            scope.$index
                                          )
                                        },
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ]
                            },
                          },
                        ]),
                      }),
                      _c("el-table-column", {
                        attrs: { label: "操作", width: "100" },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _c(
                                  "el-button",
                                  {
                                    attrs: { type: "text", size: "small" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.handlePreview(scope.row)
                                      },
                                    },
                                  },
                                  [_vm._v("预览")]
                                ),
                                _c(
                                  "el-button",
                                  {
                                    attrs: { type: "text", size: "small" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.handleDelete(scope.row)
                                      },
                                    },
                                  },
                                  [_vm._v("删除")]
                                ),
                              ]
                            },
                          },
                        ]),
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            [
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.onSubmit } },
                [_vm._v("保存")]
              ),
              _c("el-button", { on: { click: _vm.onCancel } }, [
                _vm._v("取消"),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "视频预览",
            visible: _vm.openVideoPlay,
            width: "800px",
            "append-to-body": "",
          },
          on: {
            close: function () {
              _vm.openVideoPlay = false
              _vm.currentVideoUrl = ""
            },
            "update:visible": function ($event) {
              _vm.openVideoPlay = $event
            },
          },
        },
        [
          _c("video", {
            staticStyle: { width: "720px" },
            attrs: { src: _vm.currentVideoUrl, autoplay: "", controls: "" },
          }),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }